<template>
  <div class="church-communications template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcChurchContacts }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>
    <!-- / Page Header -->
    <page-body class="mx-lg">
      <section class="section-1">
        <div class="d-flex section-header">
          <h2>{{ this.translations.tcContacts }}</h2>
          <b-button variant="primary" v-if="iCanSeeAny(secured_contact_add_controls)" @click="handleAddContactClick">
            {{ translations.tcAddContact }}
          </b-button>
          <b-button variant="primary" v-if="iCanSee(secured_gideon_rep_add_controls.add_gideon_rep_contact_button)"
            @click="handleAddGideonRepClick">
            {{ translations.tcAddGideonRep }}
          </b-button>
        </div>
        <div class="d-flex flex-wrap" v-if="translations.components">
          <ServiceCard v-for="(contact, index) in otherContacts" :i18n="translations.components" :key="index"
            :obj="contact" :secure_edit_keys="secured_contact_edit_controls"
            :secure_delete_keys="secured_contact_delete_controls"
            @profile_requested="handleContactProfileRequest(contact)"
            @edit_requested="handleContactEditRequest(contact)" @delete_approved="handleDeleteRequest(contact)">
          </ServiceCard>
          <ServiceCard v-for="(rep, index) in gideonChurchReps" :i18n="translations.components" :key="index + 50"
            :obj="rep" :secure_edit_keys="secured_gideon_rep_edit_controls"
            :secure_delete_keys="secured_gideon_rep_delete_controls"
            @profile_requested="handleContactProfileRequest(rep)" @edit_requested="handleContactEditRequest(rep)"
            @delete_approved="handleDeleteRequest(rep)"></ServiceCard>
        </div>
      </section>
    </page-body>
    <!-- / Page Body -->
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ServiceCard from '@/components/service-card/ServiceCard.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-contacts',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      backJumpObj: {
        link: '#',
        location: '',
      },
      gideonChurchRepsTranslated: {},
      otherContactsTranslated: {},
      secured_contact_add_controls: {
        add_church_contact_button: '33240766-4658-4fa4-8e7a-905f33aec5fc',
      },
      secured_contact_edit_controls: {
        edit_church_contact_button: '34d42f61-03e2-4e97-be41-e83f2265e2b3',
      },
      secured_contact_delete_controls: {
        delete_church_contact_button: '3bc09848-8225-458b-8ffb-a2619efd379b',
      },
      secured_gideon_rep_add_controls: {
        add_gideon_rep_contact_button: '5f9a5506-bd9f-4382-aa20-e8ef3980f9af',
      },
      secured_gideon_rep_edit_controls: {
        edit_gideon_rep_contact_button: 'a36fc9a4-a198-4b02-928e-ea883573a689',
      },
      secured_gideon_rep_delete_controls: {
        delete_gideon_rep_contact_button: 'f962c6d8-77e5-4b9e-8a98-4300890bf557',
      },
    }
  },
  async created() {
    await Promise.all([
      this.getTranslations(),
      this.getComponentTranslations('back-jump', 'quick-jump', 'service-card'),
      this.getComponentTranslations('common.individual-relationship-type'),
      this.pageLoad(),
    ]).then((results) => {
      this.stripReadableText(results[2])
      const translatedText = {
        ...results[1],
        common: { ...this.translations.common },
      }
      this.$set(this.translations, 'components', translatedText)

      this.translateDropdownOptions([
        { node: 'individual-relationship-type', store: 'otherContacts' },
        { node: 'individual-relationship-type', store: 'gideonChurchReps' },
      ])
    })
  },
  methods: {
    ...mapActions({
      deleteChurchContact: 'churchMinistry/addChurchContact',
      deleteGideonRep: 'churchMinistry/addChurchGideonRep',
      getChurchContacts: 'churchMinistry/getChurchContacts',
      loadChurchDetail: 'churchMinistry/loadChurchDetailSelect',
      setActiveChurchContactType: 'churchMinistry/setActiveChurchContactType',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedChurchContact: 'user/setSelectedChurchContact',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async handleAddContactClick() {
      await this.setSelectedChurchContact({})
      await this.setActiveChurchContactType(2)
      this.$router.push('/programs/cm/churchProfile/add-church-contact/1')
    },
    async handleAddGideonRepClick(ct) {
      await this.setSelectedChurchContact(ct)
      await this.setActiveChurchContactType(1)
      this.$router.push('/programs/cm/churchProfile/add-church-contact/1')
    },
    async handleContactEditRequest(ct) {
      await this.setSelectedChurchContact(ct)
      let ct_type = ct.contact_type === 'Pastor' ? 0 : ct.contact_type === 'Gideons Church Rep.' ? 1 : 2
      await this.setActiveChurchContactType(ct_type)
      this.$router.push(`/programs/cm/churchProfile/add-church-contact/${ct_type}`)
    },
    async removeContactFromList(ct) {
      let deletedContactIndex = this.otherContactsTranslated.findIndex((c) => c.ind_key === ct.ind_key)
      this.otherContactsTranslated.splice(deletedContactIndex, 1)
    },
    async handleDeleteRequest(ct) {
      ct.ind_delete_flag = true
      ct.ixo_delete_flag = true
      let result = true
      this.setLoadingStatus(true)
      // if the contact to be deleted and is primary, grab the 1st person from church contacts 
      // and send the ind_key with the post to be the new primary contact (new_primary_key)
      if (ct.primary && this.churchContacts.length > 0) {
        let newprimary = this.churchContacts.filter(oc => {
          return oc.primary === false
        })
        ct.new_primary_key = (!!newprimary && newprimary.length > 0) ? newprimary[0].ind_key : null
      }
      if (ct.contact_type === 'Gideons Church Rep.') {
        await Promise.all([(result = await this.deleteGideonRep(ct))]).then((result) => {
          if (result) {
            this.removeContactFromList(ct).then(() => {
              this.$swal({
                icon: result ? 'success' : 'error',
                showCancelButton: false,
                text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
                confirmButtonText: this.translations.tcOk,
              }).then(() => {
                this.pageLoad()
              })
            })
          }
        })
      } else {
        await Promise.all([(result = await this.deleteChurchContact(ct))]).then((result) => {
          if (result) {
            this.removeContactFromList(ct).then(() => {
              this.$swal({
                icon: result ? 'success' : 'error',
                showCancelButton: false,
                text: result ? this.translations.tcDeleteSuccessful : this.translations.tcDeleteFailed,
                confirmButtonText: this.translations.tcOk,
              }).then(() => {
                this.pageLoad()
              })
            })
          }
        })
      }
      this.setLoadingStatus(false)
    },
    async handleContactProfileRequest(ct) {
      await this.setSelectedIndividualKey(ct.ind_key)
      if (ct.contact_type === 'Gideons Church Rep.') {
        this.$router.push('/programs/mb/member-profile-cm/')
      } else {
        this.$router.push('/programs/mb/non-member-profile-cm/')
      }
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.loadChurchDetail(),
        await this.getChurchContacts(this.userSelectedChurchKey),
      ]).then(() => {
        this.backJumpObj.location = this.churchDetail.church_name
        this.backJumpObj.link = '/programs/cm/church-profile'
        this.setLoadingStatus(false)
      })
    },
  },
  computed: {
    ...mapGetters({
      churchContacts: 'churchMinistry/churchContacts',
      churchDetail: 'churchMinistry/churchDetailSelect',
      iCanSee: 'user/iCanSee',
      iCanSeeAny: 'user/iCanSeeAny',
      prefCulture: 'user/userPreferredCulture',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
    }),
    gideonChurchReps() {
      return this.churchContacts.filter((gcr) => gcr.contact_type === 'Gideon Church Rep.')
    },
    otherContacts() {
      return this.churchContacts.filter((gcr) => gcr.contact_type !== 'Gideon Church Rep.')
    },
  },
  components: {
    BackJump: BackJump,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ServiceCard: ServiceCard,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';

.church-communications {
  header h1 {
    text-align: center;
    padding-bottom: 80px;

    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }

  .section-header {
    margin-bottom: 45px;
    align-items: center;

    @include breakpoint(sm) {
      flex-wrap: wrap;
    }

    h2 {
      font-size: 42px;
      font-family: $DIN;
      line-height: 44px;
      letter-spacing: 1px;
      color: #000;
      margin-right: 30px;
      margin-bottom: 0;
      text-transform: uppercase;

      @include breakpoint(sm) {
        margin-bottom: 35px;
      }
    }

    .btn {
      margin-right: 10px;
      display: flex;
      align-items: center;

      @include breakpoint(sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .section-1,
  .section-2 {
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .section-1 {
    margin-bottom: 75px;
    margin-top: 30px;

    @include breakpoint(sm) {
      margin-bottom: 15px;
    }
  }

  .section-2 {
    margin-bottom: 20px;
  }
}
</style>
